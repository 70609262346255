import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import './HomePage.css'
import {
  CANVAS_HEIGHT,
  CANVAS_WIDTH,
  checkOrientation,
  detectMobile,
  detectIOS,
} from './puzzlePageHelpers'
// @ts-ignore
import * as mixpanel from 'mixpanel-browser'
mixpanel.init('7a0bebb5b555d826b672d4c69d3f6dd3', {
  debug: false,
})
mixpanel.track('pageVisit', {
  url: '/',
})

const BG_HEIGHT = 964
const BG_WIDTH_MIN = 1420
const HomePage = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [screenHeight, setScreenHeight] = useState(window.innerHeight)
  const [screenScale, setScreenScale] = useState(
    Math.max(window.innerWidth / BG_WIDTH_MIN, 1),
  )
  const [iphoneZoom, setIphoneZoom] = useState(detectIOS() ? 0.5 : 1)
  // Effect that adds the event listener to the window on mount and removes it on unmount
  useEffect(() => {
    const handleResize = () => {
      // Update the screen width state variable when the window is resized
      setScreenWidth(window.innerWidth)
      setScreenHeight(window.innerHeight)
      if (detectMobile()) {
        if (checkOrientation() === 'landscape') {
          const newZoom = window.innerWidth / BG_WIDTH_MIN
          // @ts-ignore
          document.body.style.zoom = `${newZoom * 100}%`
        } else {
          const newZoom = window.innerHeight / BG_HEIGHT
          // @ts-ignore
          document.body.style.zoom = `${newZoom * 100}%`
        }
      }
    }
    // if (detectIOS()) {
    //   setIphoneZoom(0.8)
    // } else {
    //   setIphoneZoom(1.0)
    // }
    if (detectMobile()) {
      if (checkOrientation() === 'landscape') {
        const newZoom = screenWidth / BG_WIDTH_MIN
        // @ts-ignore
        document.body.style.zoom = `${newZoom * 100}%`
      } else {
        const newZoom = screenHeight / BG_HEIGHT
        // @ts-ignore
        document.body.style.zoom = `${newZoom * 100}%`
      }
    }
    // Add the event listener
    window.addEventListener('resize', handleResize)

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, []) // Empty dependency array ensures this effect runs only once on mount

  return (
    <>
      <style>
        {`
          footer {
            margin-top: -2px;
          }
        `}
      </style>
      <div
        id="desktopHome"
        style={{
          backgroundColor: 'black',
          fontFamily: 'Inter',
        }}
      >
        <div
          style={{
            backgroundImage: `url(/images/home-bg.png)`,
            backgroundSize: `100%`,
            backgroundPosition: 'left top',
            height: Math.max(
              (BG_HEIGHT / BG_WIDTH_MIN) * screenWidth,
              BG_HEIGHT,
            ),
            width: '100%',
            minWidth: BG_WIDTH_MIN,
            margin: '0 auto',
          }}
        >
          <div
            style={{
              color: 'white',
              fontSize: 45 * screenScale * iphoneZoom,
              fontWeight: 700,
              marginLeft: 126 * screenScale,
              paddingTop: 428 * screenScale,
              width: 207 * screenScale,
              textAlign: 'center',
              display: 'inline-block',
            }}
          >
            <NavLink to="/puzzle">
              Start
              <br />
              <div id="animateArrow">
                <img
                  src="/white-down-arrow.png"
                  alt="white down arrow"
                  style={{
                    margin: '0 auto',
                    marginTop: 20 * screenScale,
                  }}
                />
              </div>
              <br />
              <img
                src="/white-puzzle-piece.png"
                alt="white puzzle piece"
                style={{
                  marginTop: -80 * screenScale,
                  marginLeft: 8 * screenScale,
                  width: 207 * screenScale,
                }}
              />
            </NavLink>
          </div>
          <div
            style={{
              display: 'inline-block',
              width: 495 * screenScale,
              verticalAlign: 'top',
              textAlign: 'center',
              // marginLeft: 512,
              float: 'right',
              marginRight: 96 * screenScale,
            }}
          >
            <div
              style={{
                fontSize: 60 * screenScale * iphoneZoom,
                fontWeight: 700,
                lineHeight: `${60 * screenScale}px`,
                marginTop: 124 * screenScale,
              }}
            >
              Solving the <br />
              enterprise <br />
              learning puzzle
            </div>
            <div
              style={{
                fontSize: 26 * screenScale * iphoneZoom,
                color: 'white',
                lineHeight: `${32 * screenScale}px`,
                marginTop: 168 * screenScale,
                fontWeight: 700 * screenScale,
              }}
            >
              How to play:
              <br />
              <br />
              <span style={{ color: '#BA61FF' }}>Choose</span> the puzzle pieces
              <br />
              which describe your
              <br />
              priorities
              <br />
              <br />
              <span style={{ color: '#BA61FF' }}>Complete</span> your unique
              puzzle
              <br />
              <br />
              <span style={{ color: '#BA61FF' }}>Flip</span> the puzzle to see
              how Mentem's
              <br />
              solutions can help.
            </div>
          </div>
        </div>
      </div>

      <div
        id="mobileHome"
        style={{
          backgroundImage: `url(/images/girl-with-puzzle.png)`,
          backgroundColor: '#EBECF0',
          backgroundSize: '100%',
          backgroundRepeat: 'no-repeat',
          minHeight: '1000px',
        }}
      >
        <img
          src="/images/mobile-ink.svg"
          style={{
            minWidth: '101%',
            marginLeft: '-3px',
          }}
        />
        <div
          style={{
            position: 'absolute',
            right: 14,
            top: 22,
            fontSize: detectIOS() ? 20 : 27,
            lineHeight: detectIOS() ? '40px' : '32px',

            fontWeight: 700,
            fontFamily: 'Inter',
            color: 'black',
          }}
        >
          Solving the <br />
          enterprise <br />
          learning <br />
          puzzle
        </div>
        <div
          style={{
            marginTop: detectIOS() ? -311 : -270,
            fontSize: detectIOS() ? 24 : 32,
            fontWeight: 700,
            color: 'white',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: 'fit-content',
          }}
        >
          <NavLink to="/puzzle">
            <span
              style={{
                display: 'inline-block',
                verticalAlign: detectIOS() ? 'text-bottom' : 'super',
                marginRight: 20,
              }}
            >
              Start
            </span>
            <span
              style={{
                display: 'inline-block',
              }}
              id="animateArrowHorizontal"
            >
              <img
                src="/images/white-right-arrow.svg"
                alt="white right arrow"
                style={
                  {
                    // margin: '0 auto',
                    // marginTop: 20,
                  }
                }
              />
            </span>
            <img
              src="/white-puzzle-piece.png"
              alt="white puzzle piece"
              style={{
                display: 'inline-block',
              }}
            />
          </NavLink>
        </div>
        <div
          style={{
            color: 'black',
            fontSize: detectIOS() ? 15 : 20,
            fontWeight: 700,
            paddingBottom: 20,
          }}
          className="mx-5 mb-5 mt-10"
        >
          <div className="text-center">How to play:</div>
          <div className="pt-3">
            <span className="b-purple">Navigate</span> each puzzle as it
            symbolises the unique challenges or barriers you might encounter
            while developing new skills.
          </div>
          <div className="pt-3">
            <span className="b-purple">Complete</span> your 'M' to map your
            journey.
          </div>
          <div className="pt-3">
            <span className="b-purple">Flip</span> it to uncover the custom
            solutions Mentem provides for your professional advancement.
          </div>
        </div>
      </div>
    </>
  )
}

export default HomePage
