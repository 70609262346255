import React from "react";

const PageFour = () => {
  return (
    <div className="p-10">
      <h1 className="text-3xl font-bold">Page Two</h1>
      <p>This is the content of Page Two.</p>
    </div>
  );
};

export default PageFour;
