import { useSwipeable } from "react-swipeable";
import { animated, useSpring } from "react-spring";
import React, { useState } from "react";

interface SwipeableCardProps {
  data: any;
  onSwipeOff: (id: number, direction: string) => void;
}

const SwipeableCard2: React.FC<SwipeableCardProps> = ({ data, onSwipeOff }) => {
  const [{ x, y }, api] = useSpring(() => ({ x: 0, y: 0 }));

  const rotation = -12 + data.id;
  const swipeHandlers = useSwipeable({
    onSwiped: ({ dir, velocity }) => {
      let dirX = 0;
      let dirY = 0;
      // Determine the direction of the swipe
      if (dir === "Left") dirX = -1;
      else if (dir === "Right") dirX = 1;
      else if (dir === "Up") dirY = 0;
      else if (dir === "Down") dirY = 0;

      // Swipe off screen
      api.start({
        x: dirX * 200 * velocity,
        y: dirY * 200 * velocity,
        onRest: () => onSwipeOff(data.id, dir),
      });
    },
    onSwiping: (what) => {
      api.start({ x: what.deltaX, y: what.deltaY });
      // if (what.down) {
      //   const [mx, my] = what.movement; // Correctly destructure movement here
      //   api.start({ x: mx, y: my });
      // }
    },
    onTouchEndOrOnMouseUp: (what) => {},
    trackMouse: true,
  });
  return (
    <animated.div
      {...swipeHandlers}
      style={{
        touchAction: "none",
        userSelect: "none",
        position: "absolute",
        width: 380,
        height: 500,
        padding: 40,
        borderRadius: 32,
        boxShadow: "0 10px 30px -5px rgba(0, 0, 0, 0.3)",
        transform: x.to(
          (x) => `translate3d(${x}px, ${y.get()}px, 0) rotate(${rotation}deg)`
        ),
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        background: data.background,
        color: data.color,
        // display: 'flex',
        // justifyContent: 'center', // Center horizontally
        // alignItems: 'center', // Center vertically
        // top: '50%',
        // left: '50%',
        // transform: x.to(x => `translate3d(${x - 150}px, ${y.get() - 150}px, 0)`), // Adjust for the card size to truly center
      }}
    >
      <div
        style={{
          fontWeight: 700,
          background: "rgba(255, 255, 255, 0.12)",
          borderRadius: "4px",
          display: "inline-block",
          padding: "4px 8px",
          fontSize: "16px",
          letterSpacing: "0",
          lineHeight: "24px",
        }}
      >
        {data.header}
      </div>
      <div
        style={{
          fontSize: "32px",
          fontWeight: "800",
          letterSpacing: "0",
          lineHeight: "40px",
          marginTop: "16px",
        }}
      >
        {data.desc}
      </div>
    </animated.div>
  );
};

function PageOne() {
  const [cards, setCards] = useState([
    {
      id: 1,
      header: "Time-consuming analysis",
      desc: "Analysis is expensive and time-consuming",
      background: "rgb(25, 0, 65)",
      color: "rgb(255, 255, 255)",
    },
    {
      id: 2,
      header: "Standardizing research",
      desc: "Driving consistency in our research practice across the team",
      background: "rgb(239, 234, 255)",
      color: "rgb(25, 0, 65)",
    },
    {
      id: 3,
      header: "Stakeholder buy-in",
      desc: "Stakeholders don’t engage with our findings and sharing is difficult",
      background: "rgb(201, 185, 255)",
      color: "rgb(25, 0, 65)",
    },
    {
      id: 4,
      header: "Fragmented tools",
      desc: "Insights and data are siloed across multiple tools in the organization",
      background: "rgb(25, 0, 65)",
      color: "rgb(255, 255, 255)",
    },
    {
      id: 5,
      header: "Collaboration",
      desc: "Engagement is low and collaboration is difficult",
      background: "rgb(201, 185, 255)",
      color: "rgb(25, 0, 65)",
    },
    {
      id: 6,
      header: "Traceable findings",
      desc: "Research reports and presentations lose the context of the original raw data",
      background: "rgb(239, 234, 255)",
      color: "rgb(25, 0, 65)",
    },
    {
      id: 7,
      header: "Data security",
      desc: "Maintaining and adhering to data requirements and policies",
      background: "rgb(25, 0, 65)",
      color: "rgb(255, 255, 255)",
    },
  ]);

  const [allSwiped, setAllSwiped] = useState(false);
  // const handleSwipe = (id: any, direction: any) => {
  //   console.log(`Swiped card ${id} to the ${direction}`)
  //   // Remove the card from the array
  //   setCards((prevCards) => prevCards.filter((card) => card.id !== id))
  // }
  const [helloWorldStyle, helloWorldApi] = useSpring(() => ({
    from: { transform: "translate3d(0,100%,0)", opacity: 0 },
    opacity: 0,
    transform: "translate3d(0,100%,0)",
  }));
  const handleSwipeOff = (id: any, direction: string) => {
    // Remove the card from the array

    if (direction === "Up") return;
    if (direction === "Down") return;

    setCards((prevCards) => {
      const newCards = prevCards.filter((card) => card.id !== id);
      if (newCards.length === 0) {
        // All cards have been swiped, trigger Hello World animation
        setAllSwiped(true);
        helloWorldApi.start({ opacity: 1, transform: "translate3d(0,0%,0)" });
      }
      return newCards;
    });
  };

  return (
    <div className="">
      <link
        href="https://fonts.googleapis.com/css2?family=Jakarta+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap"
        rel="stylesheet"
      />

      <main className="flex h-[100%] flex-col pb-10 pt-16">
        <div
          style={{
            fontFamily: '"Jakarta Sans", sans-serif',

            position: "relative",
            height: "600px",
            width: "800px",
            display: "flex", // Add flex display
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
            overflow: "hidden",
            border: "thin red solid",
          }}
        >
          {cards.map((card, index) => (
            <SwipeableCard2
              data={card}
              // onSwipe={handleSwipe}
              onSwipeOff={handleSwipeOff}
            />
          ))}
          {allSwiped && (
            <animated.div
              style={{
                ...helloWorldStyle,
                position: "absolute",
                width: "100%",
                textAlign: "center",
                fontSize: "2rem",
                bottom: "40px",
              }}
            >
              You’ve selected 6 challenges
            </animated.div>
          )}
        </div>
      </main>
    </div>
  );
}

export default PageOne;
