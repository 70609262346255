import React, { useEffect, useState } from 'react'
import PuzzlePiece from '../components/PuzzlePiece'
import * as _ from 'lodash'
import './PuzzlePage.css'
import 'react-toastify/dist/ReactToastify.css'
import {
  CANVAS_WIDTH,
  CANVAS_HEIGHT,
  BOXES_PER_ROW,
  BOX_WIDTH,
  BOX_HEIGHT,
  BG,
  BG_ALPHA,
  INITIAL_CARDS,
  SIX_PANEL_PROTRUSIONS,
  getRandom,
  playSound,
  MODAL_2_PERSONAS,
  detectMobile,
  detectIOS,
  detectAndroid,
  checkOrientation,
} from './puzzlePageHelpers'
// @ts-ignore
import * as mixpanel from 'mixpanel-browser'
mixpanel.init('7a0bebb5b555d826b672d4c69d3f6dd3', {
  debug: false,
})
const WTF_64 = 64
const MODAL_1_FADEOUT_DELAY = 2000
const DEBUG = false

mixpanel.track('pageVisit', {
  url: '/puzzle',
})

function ModalStepOne({
  isOpen = false,
  cards = [],
  onClose = () => {},
  onComplete = () => {},
  returningFromStepTwo = false,
  setReturningFromStepTwo = (_: boolean) => {},
  realTrueActualZoom = 1,
  cssZoom = 1,
}) {
  const [fadeOutAnimate, setFadeOutAnimate] = useState(false)
  const [timeout1id, setTimeout1id] = useState(null)
  const [timeout2id, setTimeout2id] = useState<any>(null)
  const [whichCardEntering, setWhichCardEntering] = useState<any>(null)

  useEffect(() => {
    if (returningFromStepTwo) {
      // Prevent automatic fade-out if returning from ModalStepTwo
      setReturningFromStepTwo(false)
      if (fadeOutAnimate) {
        setFadeOutAnimate(false)
      }
      if (timeout1id) {
        clearTimeout(timeout1id)
      }
      if (timeout2id) {
        clearTimeout(timeout2id)
      }
    }
  }, [returningFromStepTwo])

  useEffect(() => {
    // clearTimeouts
    // to avoid race conditions
    // when cards swiped quickly
    if (timeout1id) {
      setFadeOutAnimate(false)
      clearTimeout(timeout1id)
    }
    if (timeout2id) {
      setFadeOutAnimate(false)
      clearTimeout(timeout2id)
    }
    if (cards.length > 0 && cards.length < 6) {
      const timeout1id: any = setTimeout(() => {
        setFadeOutAnimate(true)
        const timeout2id = setTimeout(() => {
          setFadeOutAnimate(false)
          // if (cards.length >= 6) {
          //   onComplete()
          // } else {
          onClose()
          // }
        }, MODAL_1_FADEOUT_DELAY)
        setTimeout2id(timeout2id)
      }, 2000 * (DEBUG ? 2000 : 1)) // keep open forever if debug
      setTimeout1id(timeout1id)
    }
    console.log(
      `setWhichCardEntering = ${!returningFromStepTwo ? cards.length : null}`,
    )
    setWhichCardEntering(!returningFromStepTwo ? cards.length : null)
  }, [cards.length])

  if (!isOpen) {
    return null
  }
  function SixGrid(props: any) {
    const SCALE = 0.8
    return (
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap', // Add flex-wrap styling
          width: SCALE * (300 + 5) * 3,
          height: SCALE * (430 + 5) * 2,
          // backgroundColor: '#fff', // Optional: change as needed
          margin: '0px', // Set margin to 0px to better utilize available space
          padding: '0px',
          ...(props.style || {}),
        }}
      >
        {cards.map((card: any, index: any) => {
          if (index > 5) {
            return null
          }
          let protrusion = SIX_PANEL_PROTRUSIONS[index]
          protrusion = {
            left: 'FLAT',
            top: 'FLAT',
            right: 'FLAT',
            bottom: 'FLAT',
          }
          console.log(
            `card for loop index ${index} = ${card.id}, whichCardEntering = ${whichCardEntering}`,
          )
          return (
            <div
              key={card.id}
              style={{
                width: SCALE * (300 + 5),
                height: SCALE * (430 + 5),
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '0px', // Margin between puzzle pieces
              }}
            >
              <PuzzlePiece
                {...card}
                scale={SCALE}
                rotation={0}
                transformX={protrusion?.left === 'OUT' ? 0 : 39}
                transformY={protrusion?.top === 'OUT' ? 0 : 60}
                pieceIndex={index}
                {...protrusion}
                // isEntering={!returningFromStepTwo && index === cards.length - 1}
                isEntering={whichCardEntering === index + 1}
                setWhichCardEntering={setWhichCardEntering}
                cssZoom={cssZoom}
                realTrueActualZoom={realTrueActualZoom}
                isMobile={detectMobile()}
                onModal1={true}
              />
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
      }}
    >
      <div
        style={{
          // display: 'flex',
          // flexDirection: 'column',
          // alignItems: 'center',
          // justifyContent: 'center',
          opacity: fadeOutAnimate ? 0 : 1, // Control visibility with opacity
          transition: `opacity ${MODAL_1_FADEOUT_DELAY}ms ease-in-out`, // Smooth transition for the opacity
        }}
      >
        {detectMobile() && (
          <div
            style={{
              width: CANVAS_WIDTH,
              whiteSpace: 'nowrap',
            }}
          >
            <div
              style={{
                // marginLeft: 500,
                float: 'right',
                whiteSpace: 'pre-wrap',
                marginRight: -70,
              }}
            >
              <SixGrid />
            </div>
            <style>
              {`
              @keyframes flipThisAnimation {                                                  
  from {                                                                        
    transform: rotateY(0deg);                                                   
  }                                                                             
  to {                                                                          
    transform: rotateY(360deg);                                                 
  }                                                                             
}                                                                               
#flipThis:hover {                                                                     
  animation: flipThisAnimation 2s linear infinite;                              
}    
              `}
            </style>
            <div
              style={{
                color: 'white',
                marginLeft: -40,
                fontSize: detectIOS() ? 15 : 45,
                fontWeight: 700,
                fontFamily: '"Inter"',
                paddingTop: 200,
              }}
            >
              My challenge map
            </div>
            <button
              style={{
                fontSize: detectIOS() ? 15 : 45,
                marginLeft: -40,
                fontWeight: 700,
                fontFamily: '"Inter"',
                paddingTop: 100,
                zIndex: 1001,
                color: 'white',
                maxWidth: '50%',
                whiteSpace: 'pre-wrap',
                textAlign: 'left',
              }}
              onClick={(e) => {
                onComplete()
                e.stopPropagation()
              }}
            >
              Check out our solutions
              <img
                id="flipThis"
                src="/images/im-done-white.svg"
                alt="back"
                style={{
                  display: 'inline-block',
                  marginLeft: 40,
                  width: detectIOS() ? 120 : 120,
                }}
              />
            </button>
          </div>
        )}
        {!detectMobile() && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'white',
              borderRadius: 10,
              boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
            }}
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <h2
              style={{
                // lineHeight: '24px',
                height: 36,
                marginTop: 12,
                marginBottom: 12,
                fontSize: 18,
                fontWeight: 700,
                fontFamily: '"Inter"',
              }}
            >
              My challenge puzzle map
            </h2>
            <SixGrid
              style={{
                // misalignment came out of nowhere. get rid of this if it's fixed
                marginRight: -11,
                marginLeft: 15,
                marginTop: 22,
              }}
            />
            <button
              style={{
                height: 36,
                marginTop: 12,
                marginBottom: 12,
                fontSize: 18,
                fontWeight: 700,
                fontFamily: '"Inter"',
                zIndex: 1001,
              }}
              onClick={(e) => {
                onComplete()
                e.stopPropagation()
              }}
            >
              Check out our solutions
              <img
                src="/images/im-done.svg"
                alt="back"
                style={{
                  display: 'inline-block',
                  marginLeft: 40,
                }}
              />
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

function ModalStepTwo({
  isOpen = false,
  onClose = () => {},
  onComplete = () => {},
  onBack = () => {},
  cards = [],
  cssZoom = 1,
  realTrueActualZoom = 1,
}) {
  if (!isOpen) {
    return null
  }

  function getPersona(cards: any) {
    const personas = MODAL_2_PERSONAS
    // Count the card types
    const cardCounter = _.countBy(cards, 'type')
    // Find the max number of cards for a persona
    const maxCards = Math.max(...Object.values(cardCounter))
    // find winning persona(s)
    const winningPersonas = Object.entries(cardCounter)
      .filter((x) => x[1] === maxCards)
      .map((x) => x[0])

    // if there is a tie, return a random persona from the winning personas
    let winningPersona =
      winningPersonas.length > 1
        ? getRandom(winningPersonas)
        : winningPersonas[0]

    const chosenPersona = personas.find(
      (x) => x(null).type === winningPersona,
    )?.(cards)

    mixpanel.track('userGetsPersona', {
      personaType: chosenPersona?.type,
      personaName: chosenPersona?.name,
      cardsSelected: cards.map((x: any) => x.id),
      cardsSelectedNames: cards.map((x: any) => x.name),
    })
    return chosenPersona || personas[0](cards)
  }

  const chosenPersona = getPersona(cards)
  const _X = 580
  const _Y = 284
  const SCALE = 2.4
  let iosZoomFactor = 1
  if (detectIOS()) {
    iosZoomFactor *= 0.35
  }
  return (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
        fontFamily: '"Inter"',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          ...(!detectMobile() && {
            backgroundColor: 'white',
          }),
          borderRadius: 5 * iosZoomFactor,
          boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
        }}
      >
        {detectMobile() && (
          <>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap', // Add flex-wrap styling
                // width: 1098, // 0.6 * (300 + 5) * 6
                // height: 522, // 0.6 * (430 + 5) * 2
                width: _X * SCALE, // 0.6 * (300 + 5) * 6
                height: _Y * SCALE, // 0.6 * (430 + 5) * 2
                margin: '0px', // Set margin to 0px to better utilize available space
                padding: '0px',
                position: 'relative',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundImage: "url('/images/modal-2-mobile-bg.png')",
                  borderRadius: 5 * iosZoomFactor,
                }}
              ></div>
              <div
                style={{
                  position: 'absolute',
                  width: _X * SCALE,
                  height: _Y * SCALE,
                  backgroundSize: `${(_X + 1) * SCALE}px ${(_Y + 1) * SCALE}px`,
                  backgroundPosition: '0px -1px',
                  backgroundImage: "url('/images/puzzle-cage-wide.png')",
                  borderRadius: 5 * iosZoomFactor,
                }}
              ></div>
              <div
                style={{
                  position: 'absolute',
                  width: _X * SCALE * 0.9,
                  borderRadius: '12px',
                  padding: `${12 / realTrueActualZoom}px ${
                    12 / realTrueActualZoom
                  }px ${12 / realTrueActualZoom}px`,
                  transform: 'translate(-50%, -50%)',
                  textAlign: 'center',
                }}
                className="transform(-50%, -50%) fixed left-1/2 top-1/2 z-10 flex justify-around rounded-xl bg-white p-9 text-center"
              >
                <div
                  style={{
                    minWidth: '50%',
                    maxWidth: '50%',
                  }}
                  className="flex flex-col items-center pr-4"
                >
                  <div
                    style={{
                      fontSize: 45 * iosZoomFactor,
                      // ...(detectIOS() && {
                      //   fontSize: 15,
                      // }),
                      fontWeight: 700,
                      marginTop: 12,
                      marginBottom: 24,
                    }}
                  >
                    Your problem(s)
                  </div>
                  <div
                    style={{
                      fontSize: 31 * iosZoomFactor,
                      fontWeight: 400,
                      textAlign: 'left',
                    }}
                    // dangerouslySetInnerHTML={{ __html: chosenPersona.desc }}
                  >
                    {chosenPersona.desc}
                  </div>
                </div>
                <div className="flex flex-col items-center pl-4">
                  <div
                    style={{
                      fontSize: 45 * iosZoomFactor,
                      fontWeight: 700,
                      marginTop: '12px',
                      marginBottom: '24px',
                      color: '#BA61FF',
                    }}
                  >
                    Our solutions
                  </div>
                  <div
                    style={{
                      fontSize: 31 * iosZoomFactor,
                      fontWeight: 400,
                      textAlign: 'left',
                    }}
                    // dangerouslySetInnerHTML={{ __html: chosenPersona.solution }}
                  >
                    {chosenPersona.solution}
                  </div>
                </div>
              </div>
            </div>
            <button
              style={{
                // height: 36,
                // marginTop: 12,
                marginTop: 14 / realTrueActualZoom,
                fontSize: 41 * iosZoomFactor,
                fontWeight: 700,
                color: 'white',
              }}
              onClick={(e) => {
                onBack()
                e.stopPropagation()
              }}
            >
              <img
                src="/images/thin-left-arrow-white.svg"
                alt="back"
                style={{
                  display: 'inline-block',
                  marginRight: 40 / realTrueActualZoom,
                  width: 43 / realTrueActualZoom,
                }}
              />
              Flip back to my puzzle
            </button>
          </>
        )}
        {!detectMobile() && (
          <>
            <h2
              style={{
                // lineHeight: '24px',
                height: 36,
                marginTop: 12,
                // marginBottom: 12,
                fontSize: 18,
                fontWeight: 700,
              }}
            ></h2>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap', // Add flex-wrap styling
                width: 1098, // 0.6 * (300 + 5) * 6
                height: 522, // 0.6 * (430 + 5) * 2
                margin: '0px', // Set margin to 0px to better utilize available space
                padding: '0px',
                position: 'relative',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundImage: "url('/images/purple-people-wide.png')",
                  borderRadius: 5,
                }}
              ></div>
              <div
                style={{
                  position: 'absolute',
                  width: 1098,
                  height: 523,
                  backgroundSize: '1099px 524px',
                  backgroundPosition: '0px -1px',
                  backgroundImage: "url('/images/puzzle-cage-wide.png')",
                  borderRadius: 5,
                }}
              ></div>
              <div
                style={{
                  position: 'absolute',
                  width: 900,
                  borderRadius: '12px',
                  padding: '24px 36px 14px',
                  transform: 'translate(-50%, -50%)',
                  textAlign: 'center',
                }}
                className="transform(-50%, -50%) fixed left-1/2 top-1/2 z-10 flex justify-around rounded-xl bg-white p-9 text-center"
              >
                <div
                  style={{
                    minWidth: '50%',
                    maxWidth: '50%',
                  }}
                  className="flex flex-col items-center pr-4"
                >
                  <img
                    src="/images/company-mugshot.png"
                    alt="company mugshot"
                    style={{
                      margin: '0 auto',
                      width: 93,
                    }}
                  />
                  <div
                    style={{
                      fontSize: '20px',
                      fontWeight: 700,
                      marginTop: '12px',
                      marginBottom: '24px',
                    }}
                  >
                    Company profile
                  </div>
                  <div
                    style={{
                      fontSize: '20px',
                      fontWeight: 400,
                      textAlign: 'left',
                    }}
                    // dangerouslySetInnerHTML={{ __html: chosenPersona.desc }}
                  >
                    {chosenPersona.desc}
                  </div>
                </div>
                <div className="flex flex-col items-center pl-4">
                  <img
                    src="/images/beaker.svg"
                    alt="beaker"
                    style={{
                      margin: '0 auto',
                      padding: '3px 0',
                    }}
                  />
                  <div
                    style={{
                      fontSize: '20px',
                      fontWeight: 700,
                      marginTop: '12px',
                      marginBottom: '24px',
                    }}
                  >
                    Mentem's matching solution
                  </div>
                  <div
                    style={{
                      fontSize: '20px',
                      fontWeight: 400,
                      textAlign: 'left',
                    }}
                    // dangerouslySetInnerHTML={{ __html: chosenPersona.solution }}
                  >
                    {chosenPersona.solution}
                  </div>
                </div>
              </div>
            </div>
            <button
              style={{
                height: 36,
                marginTop: 12,
                marginBottom: 12,
                fontSize: 18,
                fontWeight: 700,
              }}
              onClick={(e) => {
                onBack()
                e.stopPropagation()
              }}
            >
              <img
                src="/thin-left-arrow.png"
                alt="back"
                style={{
                  display: 'inline-block',
                  marginRight: 40,
                }}
              />
              Flip back to my puzzle
            </button>
          </>
        )}
      </div>
    </div>
    // </div>
  )
}

function PuzzlePage() {
  const [showRejectAnimation, setShowRejectAnimation] = useState(false)
  const [showAcceptAnimation, setShowAcceptAnimation] = useState(false)

  const [modalStepOneOpen, setModalStepOneOpen] = useState(false)
  const [modalStepTwoOpen, setModalStepTwoOpen] = useState(false)
  const [returningFromStepTwo, setReturningFromStepTwo] = useState(false)
  const [isMuted, setIsMuted] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const [isIOS, setIsIOS] = useState(false)
  const [isAndroid, setIsAndroid] = useState(false)
  const toggleMute = () => {
    setIsMuted(!isMuted)
    // Here you can also adjust the volume directly on any audio elements that are playing
  }
  const [isAllEnlarged, setIsAllEnlarged] = useState(true)
  const swipeStates = [
    useState<string | null>(null),
    useState<string | null>(null),
    useState<string | null>(null),
    useState<string | null>(null),
    useState<string | null>(null),
    useState<string | null>(null),
    useState<string | null>(null),
    useState<string | null>(null),
    useState<string | null>(null),
    useState<string | null>(null),
    useState<string | null>(null),
    useState<string | null>(null),
  ]

  const cards: any[] = [
    ...INITIAL_CARDS.map((card: any, index: number) => {
      const boxX = (index % BOXES_PER_ROW) * BOX_WIDTH
      const boxY = Math.floor(index / BOXES_PER_ROW) * BOX_HEIGHT

      return {
        ...card,

        left: card.left,
        top: card.top,
        right: card.right,
        bottom: card.bottom,
        rotation: card.rotation || '0',
        transformX: (card.transformX || 0) + boxX,
        transformY: (card.transformY || 0) + boxY,
      }
    }),
  ]

  const [chosenCards, setChosenCards] = useState<any>([])
  const [screenHeight, setScreenHeight] = useState(window.innerHeight)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  // for mobile
  // const [mobileZoom, setMobileZoom] = useState(1)
  const [cssZoom, setCssZoom] = useState(1)
  const [realTrueActualZoom, setRealTrueActualZoom] = useState(1)
  const [shouldRotate, setShouldRotate] = useState(false)
  const [scrollDistance, setScrollDistance] = useState(0)

  useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight)
      setScreenWidth(window.innerWidth)
    }

    const handleScroll = () => {
      setScrollDistance(window.scrollY)
    }

    window.addEventListener('scroll', handleScroll)
    window.addEventListener('resize', handleResize)

    // Automatically enlarge and center all pieces when the page loads
    const enlargeTimeout = setTimeout(() => {
      !isMuted && playSound('/shuffle.mp3', 1.3, null, 0.5)
      setIsAllEnlarged(false) // Set to false after a short delay to unenlarge them
    }, 500) // Adjust delay as needed for the desired effect

    setIsMobile(detectMobile())
    setIsIOS(detectIOS())
    setIsAndroid(detectAndroid())

    // // debug stuff
    // // setReturningFromStepTwo(true)
    // setModalStepOneOpen(true)
    // // add some chosed cards
    // setChosenCards(cards.slice(0, 3))
    // // end debug

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize)
      window.removeEventListener('scroll', handleScroll)
      clearTimeout(enlargeTimeout) // Clear the timeout if the component unmounts
    }
  }, [])

  useEffect(() => {
    if (isMobile) {
      if (checkOrientation() === 'landscape') {
        setShouldRotate(false)
        const newZoom = screenHeight / (CANVAS_HEIGHT + WTF_64)
        // @ts-ignore
        document.body.style.zoom = `${newZoom * 100}%`
        setCssZoom(isIOS ? 1 : newZoom)
        setRealTrueActualZoom(newZoom)
      } else {
        setShouldRotate(true) // Prompt the user to rotate their phone
        const newZoom = screenWidth / CANVAS_WIDTH
        // @ts-ignore
        document.body.style.zoom = `${newZoom * 100}%`
        setCssZoom(isIOS ? 1 : newZoom)
        setRealTrueActualZoom(newZoom)
      }
    }
  }, [isMobile, screenHeight])
  const handleRestart = () => {
    window.location.reload()
  }

  const [enlargedCardId, setEnlargedCardId] = useState(null)

  const handleSwipeOff = (id: any, direction: string) => {
    if (direction === 'Up') return
    if (direction === 'Down') return

    if (direction === 'Right') {
      mixpanel.track('userSwipesCard', {
        cardId: id,
        cardName: INITIAL_CARDS?.find((card: any) => card.id === id)?.name,
        direction: 'right',
        cardsSelected: chosenCards.map((x: any) => x.id),
        cardsSelectedNames: chosenCards.map((x: any) => x.name),
      })

      // !isMuted && playSound('/whoosh.mp3', null, null)
      !isMuted && playSound('/attach.mp3', 3.3, 3.8, 1.0)
      setChosenCards((prev: any) => {
        return [...prev, cards.find((card: any) => card.id === id)]
      })
    } else if (direction === 'Left') {
      mixpanel.track('userSwipesCard', {
        cardId: id,
        cardName: INITIAL_CARDS?.find((card: any) => card.id === id)?.name,
        direction: 'left',
        cardsSelected: chosenCards.map((x: any) => x.id),
        cardsSelectedNames: chosenCards.map((x: any) => x.name),
      })
      // setRejectedCards((prev: any) => {
      //   return [...prev, cards.find((card: any) => card.id === id)]
      // })
    }
    // console.log(`setEnlargedCardId = null (handleSwipeOff) for card ${id}`)
    setEnlargedCardId(null)
  }

  useEffect(() => {
    if (chosenCards.length) {
      setModalStepOneOpen(true)
    }
  }, [chosenCards])

  const swipeOffEnlargedCard = (direction: string) => {
    if (enlargedCardId) {
      handleSwipeOff(enlargedCardId, direction)
      const index = cards.findIndex((card: any) => card.id === enlargedCardId)
      if (index >= 0) {
        const [_, setIsSwipedOff] = swipeStates[index]
        setIsSwipedOff(direction) // Mark the card as swiped off
      }
    }
  }

  return (
    <div
      // className={`${isMobile ? 'isMobile' : 'isDesktop'}`}
      style={{
        backgroundColor: BG,
        // width: screenWidth,
        // height: screenHeight,
        // paddingLeft: (screenWidth - CANVAS_WIDTH * mobileScale) / 2,
        // paddingTop: (screenHeight - CANVAS_HEIGHT * mobileScale) / 2,
      }}
      onClick={(e) => {
        console.log(`setEnlargedCardId = null (clicking on the background)`)
        setEnlargedCardId(null)
        setModalStepOneOpen(false)
        setModalStepTwoOpen(false)
      }}
    >
      {screenWidth < CANVAS_WIDTH && (
        <style>
          {`
          footer {
              display: none;
          }
        `}
        </style>
      )}

      {shouldRotate && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1001,
          }}
        >
          <div
            style={{
              color: '#BA61FF',
              padding: 12 / realTrueActualZoom,
              backgroundColor: 'white',
              fontSize: 20 / cssZoom + 'px',
              ...(isAndroid && { lineHeight: 27 / cssZoom + 'px' }),
              fontWeight: 700,
              fontFamily: '"Inter"',
              // textAlign: 'center',
              borderRadius: 6 / realTrueActualZoom,
              width: '90%',
              justifyContent: 'center',
            }}
          >
            Please view this page in landscape view in your mobile device for a
            better experience
          </div>
          <button
            onClick={() => setShouldRotate(false)}
            style={{
              marginTop: 29 / realTrueActualZoom,
              color: 'white',
              padding: `${6 / realTrueActualZoom}px ${
                12 / realTrueActualZoom
              }px`,
              backgroundColor: '#BA61FF',
              fontSize: 16 / cssZoom,
              ...(isAndroid && { lineHeight: 20 / cssZoom + 'px' }),
              fontWeight: 700,
              fontFamily: '"Inter"',
              borderRadius: 32 / realTrueActualZoom,
            }}
          >
            Got it
          </button>
        </div>
      )}

      <div
        style={{
          ...((enlargedCardId || modalStepOneOpen || modalStepTwoOpen) && {
            backgroundColor: BG_ALPHA,
          }),
          height: CANVAS_HEIGHT + WTF_64,
          width: CANVAS_WIDTH,
          // transform: `scale(${mobileScale})`,
          transformOrigin: `0px 0px`,
          margin: '0 auto',
        }}
      >
        <div
          id="puzzleCanvas"
          style={{
            position: 'relative',
            height: CANVAS_HEIGHT + WTF_64,
            width: CANVAS_WIDTH,
            // height: 414,
            // width: 896,
            margin: '0 auto',
            backgroundColor: BG,
          }}
        >
          <div
            style={{
              height: CANVAS_HEIGHT + WTF_64,
              width: CANVAS_WIDTH,
            }}
          >
            {/* Add a restart button at the top left */}
            {!modalStepTwoOpen && (
              <>
                <button
                  onClick={() => {
                    mixpanel.track('userClicksRefresh', {
                      cardsSelected: chosenCards.map((x: any) => x.id),
                      cardsSelectedNames: chosenCards.map((x: any) => x.name),
                    })
                    handleRestart()
                  }}
                  style={{
                    position: 'absolute',
                    top: '50px',
                    left: '10px',
                    zIndex: 1001,
                    cursor: 'pointer',
                    padding: '10px',
                    border: 'none',
                    backgroundColor: 'transparent',
                  }}
                  title="Restart"
                >
                  <img src="/images/refresh-icon.svg" alt="Restart" />
                </button>
                <button
                  onClick={toggleMute}
                  style={{
                    position: 'absolute',
                    top: '50px',
                    right: '10px',
                  }}
                >
                  {!isMuted ? (
                    <img
                      src="/images/quill_mute-1.png"
                      alt="unmute"
                      width={60}
                    />
                  ) : (
                    <img src="/images/quill_mute.png" alt="mute" width={60} />
                  )}
                </button>
              </>
            )}

            {chosenCards.length >= 1 && !modalStepOneOpen && !modalStepTwoOpen && (
              <button
                onClick={(e) => {
                  mixpanel.track('userClicksImDone', {
                    cardsSelected: chosenCards.map((x: any) => x.id),
                    cardsSelectedNames: chosenCards.map((x: any) => x.name),
                  })

                  setReturningFromStepTwo(true)
                  setModalStepOneOpen(true)
                  e.stopPropagation()
                }}
                style={{
                  position: 'absolute',
                  // no scrolling on mobile
                  top: isMobile
                    ? 800
                    : Math.min(screenHeight + scrollDistance - 100, 810),
                  right: '10px',
                  padding: '10px',
                  border: 'none',
                  color: 'white', // Text color
                  fontSize: 18 / cssZoom,
                  fontWeight: 700,
                  fontFamily: '"Inter"',
                  zIndex: 100,
                }}
              >
                I’m done
                <img
                  src="/images/thin-right-arrow-white.png"
                  alt="back"
                  style={{
                    display: 'inline-block',
                    marginLeft: 40,
                    ...(isMobile && {
                      width: 36 / realTrueActualZoom,
                    }),
                  }}
                />
              </button>
            )}
            <ModalStepOne
              isOpen={modalStepOneOpen}
              // wtfMode={modalStepOneWTF}
              cards={chosenCards}
              onClose={() => setModalStepOneOpen(false)}
              onComplete={() => {
                setModalStepOneOpen(false)
                // setModalStepOneWTF(false)
                setModalStepTwoOpen(true)
              }}
              returningFromStepTwo={returningFromStepTwo}
              setReturningFromStepTwo={setReturningFromStepTwo}
              realTrueActualZoom={realTrueActualZoom}
              cssZoom={cssZoom}
            />
            <ModalStepTwo
              isOpen={modalStepTwoOpen}
              onComplete={handleRestart}
              onClose={() => {
                setModalStepTwoOpen(false)
              }}
              cards={chosenCards}
              onBack={() => {
                setModalStepTwoOpen(false)
                setReturningFromStepTwo(true)
                setModalStepOneOpen(true)
              }}
              realTrueActualZoom={realTrueActualZoom}
              cssZoom={cssZoom}
            />
            <main className={`h-[100%]`}>
              <div
                style={{
                  overflow: 'hidden',
                }}
              >
                {cards.map((card: any, index: any) => {
                  const [isSwipedOff, setIsSwipedOff] = swipeStates[index]
                  return (
                    <PuzzlePiece
                      {...card}
                      theCard={card}
                      scale={0.6}
                      isMuted={isMuted}
                      onSwipeOff={handleSwipeOff}
                      isSwipedOff={isSwipedOff}
                      setIsSwipedOff={(dir: any) => {
                        console.log(`setIsSwipedOff = ${dir}`)
                        setIsSwipedOff(dir)
                      }}
                      isEnlarged={isAllEnlarged || enlargedCardId === card.id}
                      getIsEnlarged={() =>
                        isAllEnlarged || enlargedCardId === card.id
                      }
                      setIsEnlarged={() => {
                        if (isAllEnlarged) {
                          setIsAllEnlarged(false)
                        } else {
                          setEnlargedCardId((prevEnlargedCardId) => {
                            return prevEnlargedCardId === card.id
                              ? null
                              : card.id
                          })
                        }
                      }}
                      screenWidth={screenWidth}
                      setShowRejectAnimation={setShowRejectAnimation}
                      setShowAcceptAnimation={setShowAcceptAnimation}
                      pieceIndex={card.pieceIndex || index % 6}
                      style={{
                        position: 'absolute',
                      }}
                      allowEnlarge={() => {
                        return !modalStepOneOpen && !modalStepTwoOpen
                      }}
                      cssZoom={cssZoom}
                      realTrueActualZoom={realTrueActualZoom}
                      isMobile={isMobile}
                    />
                  )
                })}
                <div
                  style={{
                    position: 'absolute',
                    width: CANVAS_WIDTH,
                    height: CANVAS_HEIGHT + WTF_64,
                    // width: 896,
                    // height: 414,
                    // paddingTop: 64,
                    backgroundColor: BG_ALPHA,
                    zIndex:
                      enlargedCardId || modalStepOneOpen || modalStepTwoOpen
                        ? 900
                        : -1,
                  }}
                  // onClick={(e) => {
                  //   console.log(
                  //     `setEnlargedCardId = null (clicking on the background)`,
                  //   )
                  //   setEnlargedCardId(null)
                  //   setModalStepOneOpen(false)
                  //   setModalStepTwoOpen(false)
                  // }}
                >
                  <div style={{ position: 'relative' }}>
                    {!modalStepOneOpen && !modalStepTwoOpen && (
                      <>
                        <style>
                          {`
                        
                          #acceptPiece.acceptHover {
                              animation: acceptPieceAnimation 2s linear infinite;
                          }
                        `}
                        </style>
                        <img
                          style={{
                            position: 'absolute',
                            left: 181,
                            top: 533,
                            minWidth: 160,
                          }}
                          src="/trash-puzzle.png"
                          alt="reject"
                          id="rejectPiece"
                          className={
                            showRejectAnimation ? 'animate-reject' : ''
                          }
                          onMouseEnter={() => {
                            document
                              .getElementById('rejectPiece')
                              ?.classList?.add('reject-hover')
                          }}
                          onMouseLeave={() => {
                            document
                              .getElementById('rejectPiece')
                              ?.classList?.remove?.('reject-hover')
                          }}
                          onClick={() => swipeOffEnlargedCard('Left')}
                        />
                        <img
                          style={{
                            position: 'absolute',
                            left: 1100,
                            top: 116,
                            minWidth: 320,
                          }}
                          src="/top-piece.png"
                          alt="accept"
                          id="acceptPiece"
                          className={
                            showAcceptAnimation ? 'animate-accept' : ''
                          }
                          onMouseEnter={() => {
                            document
                              .getElementById('acceptPiece')
                              ?.classList?.add('accept-hover')
                          }}
                          onMouseLeave={() => {
                            document
                              .getElementById('acceptPiece')
                              ?.classList?.remove?.('accept-hover')
                          }}
                          onClick={() => swipeOffEnlargedCard('Right')}
                        />

                        <img
                          style={{
                            position: 'absolute',
                            left: 1100,
                            top: 116,
                            minWidth: 320,
                          }}
                          src="/accept-puzzle.png"
                          alt="accept"
                          id="acceptPuzzle"
                          onClick={() => swipeOffEnlargedCard('Right')}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PuzzlePage
