import exp from 'node:constants'
import * as mixpanel from 'mixpanel-browser'

export const CANVAS_WIDTH = 1536
export const CANVAS_HEIGHT = 864

export const BOXES_PER_ROW = 6
export const BOX_ROWS = 2
export const BOX_WIDTH = CANVAS_WIDTH / BOXES_PER_ROW
export const BOX_HEIGHT = CANVAS_HEIGHT / BOX_ROWS

export const BG = 'rgb(56, 19, 85)'
export const BG_ALPHA = 'rgba(56, 19, 85, 0.8)'

export const SIX_PANEL_PROTRUSIONS = [
  {
    left: 'FLAT',
    top: 'FLAT',
    right: 'OUT',
    bottom: 'OUT',
  },
  {
    left: 'IN',
    top: 'FLAT',
    right: 'OUT',
    bottom: 'IN',
  },
  {
    left: 'IN',
    top: 'FLAT',
    right: 'FLAT',
    bottom: 'IN',
  },
  {
    left: 'FLAT',
    top: 'IN',
    right: 'IN',
    bottom: 'FLAT',
  },
  {
    left: 'OUT',
    top: 'OUT',
    right: 'IN',
    bottom: 'FLAT',
  },
  {
    left: 'OUT',
    top: 'OUT',
    right: 'FLAT',
    bottom: 'FLAT',
  },
]
export function getRandom(choicesArray: any) {
  const randomIndex = Math.floor(Math.random() * choicesArray.length)
  return choicesArray[randomIndex]
}

export function playSound(soundFile: any, start: any, end: any, volume: any) {
  const audio = new Audio(soundFile)

  audio.currentTime = start || 0
  audio.volume = volume || 1.0
  audio.play().catch((e) => {
    if (e.name === 'NotAllowedError') {
    } else {
      console.log(e)
    }
  })
  if (end) {
    audio.ontimeupdate = function () {
      if (audio.currentTime >= end) {
        audio.pause()
      }
    }
  }
}
export const INITIAL_CARDS = [
  {
    id: 1,
    name: 'Nothing works',
    desc: 'We have <b>tried different options</b> and <b>nothing worked</b>',
    rotation: '18deg',
    left: 'FLAT',
    top: 'FLAT',
    // top: 'OUT',
    right: 'OUT',
    // right: 'FLAT',
    bottom: 'OUT',
    // bottom: 'FLAT',
    transformX: 160,
    transformY: 176,
    // noBorder: true,
    imgUrl: '/images/p-icon-0.png',
    pieceIndex: 0,
    type: 'B',
    mobileIconCentering: [84, 0, 0, 0],
    mobileDescPadding: [50, 0, 0, 30],
  },
  {
    id: 2,
    name: 'T-shaped profiles',
    desc: 'I want to develop more <b>T-shaped profiles</b>',
    rotation: '-22deg',
    left: 'FLAT',
    top: 'FLAT',
    right: 'OUT',
    bottom: 'OUT',
    transformX: 30,
    transformY: 137,
    imgUrl: '/images/p-icon-1.png',
    pieceIndex: 0,
    type: 'A',
    mobileIconCentering: [84, 0, 0, 0],
    mobileDescPadding: [50, 0, 0, 30],
  },
  {
    id: 3,
    name: 'New skills',
    desc: 'What <b>new skills</b> do we need for our <b>strategy</b>?',
    left: 'IN',
    top: 'FLAT',
    right: 'FLAT',
    // top: 'IN',
    // right: 'IN',
    bottom: 'IN',
    rotation: '-22deg',
    transformX: 0,
    transformY: 137,
    imgUrl: '/images/p-icon-2.png',
    pieceIndex: 2,
    type: 'A',
    mobileIconCentering: [74, 0, 0, 57],
    mobileDescPadding: [30, 0, 0, 80],
  },
  {
    id: 4,
    name: 'University quality',
    desc: 'I want <b>university quality</b> at <b>commercial speed</b>',
    rotation: '23deg',
    left: 'IN',
    top: 'FLAT',
    right: 'FLAT',
    bottom: 'OUT',
    transformY: 97,
    imgUrl: '/images/p-icon-3.png',
    pieceIndex: 2,
    type: 'B',
    mobileIconCentering: [54, 0, 0, 67],
    mobileDescPadding: [30, 0, 0, 80],
  },
  {
    id: 5,
    name: 'Relevant skills',
    desc: 'We need skills <b>relevant to our business</b>',
    rotation: '12deg',
    left: 'IN',
    top: 'FLAT',
    right: 'OUT',
    bottom: 'IN',
    transformX: -70,
    transformY: 136,
    imgUrl: '/images/p-icon-4.png',
    imgWidth: 100,
    pieceIndex: 1,
    type: 'C',
    mobileIconCentering: [95, -25, 0, 58],
    mobileDescPadding: [40, 0, 0, 70],
    mobileImgWidth: 230,
  },
  {
    id: 6,
    name: 'Demonstrate ROI',
    desc: 'How can I <b>demonstrate ROI</b> in L&D?',
    rotation: '-50deg',
    left: 'IN',
    top: 'FLAT',
    right: 'OUT',
    bottom: 'IN',
    transformX: -170,
    transformY: 230,
    imgUrl: '/images/p-icon-5.svg',
    pieceIndex: 1,
    type: 'D',
    mobileIconCentering: [60, 0, 0, 30],
    mobileDescPadding: [40, 0, 0, 80],
  },
  {
    id: 7,
    name: 'Skill gaps',
    desc: 'How can we identify <b>skill gaps</b>?',
    rotation: '-23deg',
    left: 'FLAT',
    top: 'IN',
    right: 'IN',
    bottom: 'FLAT',
    transformX: 50,
    transformY: 528 - CANVAS_HEIGHT / 2,
    imgUrl: '/images/p-icon-6.png',
    pieceIndex: 3,
    type: 'A',
    mobileIconCentering: [0, 20, 0, 0],
    mobileDescPadding: [60, 20, 0, 40],
  },
  {
    id: 8,
    name: 'Who to trust',
    desc: "There are <b>so many</b> providers and I don't know <b>who to trust</b>",
    rotation: '-4deg',
    left: 'FLAT',
    top: 'IN',
    right: 'IN',
    bottom: 'FLAT',
    transformY: 384 - CANVAS_HEIGHT / 2 - 70,
    imgUrl: '/images/p-icon-7.png',
    pieceIndex: 3,
    type: 'B',
    descPaddingOverride: [47, 0, 0, 0],
    mobileIconCentering: [0, 0, 0, 0],
    mobileDescPadding: [10, 50, 0, 30],
  },
  {
    id: 9,
    name: 'Measure impact',
    desc: 'How can I <b>measure learning impact?</b>',
    rotation: '8deg',
    left: 'OUT',
    top: 'OUT',
    right: 'IN',
    bottom: 'FLAT',
    transformY: 641 - CANVAS_HEIGHT / 2 - 200,
    imgUrl: '/images/p-icon-8.png',
    pieceIndex: 4,
    type: 'D',
    mobileIconCentering: [0, 20, 0, 0],
    mobileDescPadding: [50, 60, 0, 50],
  },
  {
    id: 10,
    name: 'Get people to complete',
    desc: "We can't <b>get people to complete</b> online courses",
    rotation: '-37deg',
    left: 'OUT',
    top: 'OUT',
    right: 'IN',
    bottom: 'FLAT',
    transformY: 518 - CANVAS_HEIGHT / 2 - 70,
    imgUrl: '/images/p-icon-9.png',
    pieceIndex: 4,
    type: 'C',
    mobileIconCentering: [0, 20, 0, 0],
    mobileDescPadding: [50, 60, 0, 20],
  },
  {
    id: 11,
    name: 'Develop skills quickly',
    desc: 'We need to <b>develop skills quickly</b>',
    rotation: '-20deg',
    left: 'OUT',
    top: 'OUT',
    right: 'FLAT',
    bottom: 'FLAT',
    // transformX: -100,
    transformY: 354 - CANVAS_HEIGHT / 2 - 70,
    imgUrl: '/images/p-icon-10.png',
    imgWidth: 90,
    pieceIndex: 5,
    type: 'C',
    mobileIconCentering: [0, 0, 0, 0],
    mobileDescPadding: [50, 0, 0, 40],
  },
  {
    id: 12,
    name: 'Build skills not hire skills',
    desc: 'I want to <b>build skills not hire skills</b>',
    rotation: '19deg',
    // left: 'OUT',
    left: 'FLAT',
    top: 'OUT',
    right: 'FLAT',
    bottom: 'FLAT',
    transformX: 0,
    transformY: 512 - CANVAS_HEIGHT / 2 - 70,
    imgUrl: '/images/p-icon-11.png',
    pieceIndex: 5,
    type: 'D',
    mobileIconCentering: [0, 0, 0, 0],
    mobileDescPadding: [50, 20, 0, 40],
  },
]

function Link2(props: any) {
  return (
    <a
      href={props.url}
      target="_blank"
      className="font-bold"
      style={{ color: '#BA61FF' }}
      onClick={(e) => {
        e.stopPropagation()
        mixpanel.track('userClicksLink', {
          toUrl: props.url,
          linkText: props.text,
          fromPersona: props.persona,
          fromPersonaName: MODAL_2_PERSONAS.find(
            (x: any) => x(null).type === props.persona,
          )?.(props.chosenCards)?.name,
          cardsSelected: props.chosenCards?.map((x: any) => x.id),
          cardsSelectedNames: props.chosenCards?.map((x: any) => x.name),
        })
      }}
      rel="noreferrer"
    >
      &nbsp;{props.text}&nbsp;
    </a>
  )
}
export const MODAL_2_PERSONAS = [
  (chosenCards: any) => ({
    type: 'A',
    name: 'Learning & Development',
    desc: (
      <>
        Digital literacy, data analytics, cybersecurity or sustainability?
        Building a foundation across the organisation, or taking your domain
        experts from good to great? There are so many programs and skills out
        there, which ones should you start prioritising?
      </>
    ),
    solution: (
      <>
        Successful learning starts with planning - that's why Mentem has
        invested in organisational and individual
        <Link2
          url="http://assess.mentem.co/digital2"
          text="assessment"
          persona="A"
          chosenCards={chosenCards}
        />
        as the first step of your journey. <br />
        <br />
        Take a sample
        <Link2
          url="http://assess.mentem.co/digital2"
          text="digital literacy assessment"
          persona="A"
          chosenCards={chosenCards}
        />
        , or
        <Link2
          url="https://www.mentem.co/contact/"
          text="talk to our team"
          persona="A"
          chosenCards={chosenCards}
        />
        about a bespoke test for your organisation.
      </>
    ),
  }),
  (chosenCards: any) => ({
    type: 'B',
    name: 'HR & Talent Acquisition',
    desc: (
      <>
        Bootcamps, short courses, online subscriptions, or microcredentials? VET
        or RTO or industry certified? Consultancies, Ivy League, domestic unis
        or EdTech startups? There are so many providers making bold claims - who
        can you trust?
      </>
    ),
    solution: (
      <>
        With
        <Link2
          url="https://www.mentem.co/"
          text="Mentem by UNSW"
          persona="B"
          chosenCards={chosenCards}
        />
        you get the academic excellence of a leading university with the speed
        and focus of an EdTech startup. Don't take our word for it,
        <Link2
          url="https://www.mentem.co/client-stories/"
          text="listen to our happy clients and learners"
          persona="B"
          chosenCards={chosenCards}
        />
        - our programs work and we're proud of the results.
      </>
    ),
  }),
  (chosenCards: any) => ({
    type: 'C',
    name: 'People Managers',
    desc: (
      <>
        Everything's exciting on day one - but how can you be confident learners
        will stick with the program and complete the course? And once they're
        done, how will you see them applying this learning in their work?
      </>
    ),
    solution: (
      <>
        We deliver formal learning in accessible 20-minute chunks, backed up by
        <Link2
          url="https://demo.mentem.co/"
          text="social and on-the-job learning"
          persona="C"
          chosenCards={chosenCards}
        />
        with live support from subject-matter experts and learning success.
        Check out some sample learning
        <Link2
          url="https://demo.mentem.co/"
          text="here"
          persona="C"
          chosenCards={chosenCards}
        />
        , or
        <Link2
          url="https://platform.mentem.co/?mode=demo"
          text="click here"
          persona="C"
          chosenCards={chosenCards}
        />
        to see how our platform helps you manage learning at scale.
      </>
    ),
  }),
  (chosenCards: any) => ({
    type: 'D',
    name: 'Business Leaders',
    desc: (
      <>
        You know that investing in skills is crucial, and works better than the
        hiring/firing cycle. You know that quality learning pays back over and
        over again. The challenge is - how can you demonstrate this to your
        stakeholders who approve the budget?
      </>
    ),
    solution: (
      <>
        <Link2
          url="https://platform.mentem.co/?mode=demo"
          text="Click here"
          persona="D"
          chosenCards={chosenCards}
        />
        for a hands-on demo of our platform to manage and report on learning
        programs at scale. We measure competencies pre- and post-learning and
        can also help you track outcomes over time using the Kirkpatrick model.
        And just
        <Link2
          url="https://www.mentem.co/client-stories/"
          text="listen to some of our clients"
          persona="D"
          chosenCards={chosenCards}
        />
        talk about the impact on their business and people.
      </>
    ),
  }),
]

export function isChrome() {
  // @ts-ignore
  let isChromium = window.chrome
  let winNav = window.navigator
  let vendorName = winNav.vendor
  // @ts-ignore
  let isOpera = typeof window.opr !== 'undefined'
  let isIEedge = winNav.userAgent.indexOf('Edg') > -1
  let isIOSChrome = winNav.userAgent.match('CriOS')

  return (
    (isChromium !== null &&
      typeof isChromium !== 'undefined' &&
      vendorName === 'Google Inc.' &&
      !isOpera &&
      !isIEedge) ||
    isIOSChrome
  )
}

export function detectAndroid() {
  // @ts-ignore
  const userAgent = navigator.userAgent || navigator.vendor || window.opera
  return /android/i.test(userAgent)
}
export function detectIOS() {
  // @ts-ignore
  const userAgent = navigator.userAgent || navigator.vendor || window.opera
  // @ts-ignore
  return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream
}

export function detectMobile() {
  return detectAndroid() || detectIOS()
}

export function checkOrientation() {
  if (window.innerWidth > window.innerHeight) {
    return 'landscape'
  } else {
    return 'portrait'
  }
}
export function phoneify(
  style: any,
  isIOS: boolean,
  realTrueActualZoom: number,
) {
  return {
    ...style,
    ...(style.fontSize && {
      fontSize: isIOS ? style.fontSize : style.fontSize * realTrueActualZoom,
    }),
  }
}
