import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import * as Sentry from '@sentry/react'
import TagManager from 'react-gtm-module'

const env = process.env.REACT_APP_ENV
if (env === 'dev' || env === 'prd') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    tracesSampleRate: 0.1,
    environment: env,
  })

  // const gtmId = process.env.REACT_APP_GTM_ID
  // if (gtmId) {
  //   const tagManagerArgs = {
  //     gtmId: gtmId,
  //     auth: process.env.REACT_APP_GTM_AUTH,
  //     preview: process.env.REACT_APP_GTM_PREVIEW,
  //   }
  //   TagManager.initialize(tagManagerArgs)
  // }
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
