import React from 'react'

import PuzzlePiece from '../components/PuzzlePiece' // Import the new component

const PageThree = () => {
  return (
    <div
      className="p-10"
      style={{
        backgroundColor: 'lightgray',
      }}
    >
      <h1 className="text-3xl font-bold">Page Three</h1>
      <p>This is the content of Page Three.</p>
      <div>
        {/* top left border */}

        <PuzzlePiece
          left="FLAT"
          top="FLAT"
          right="OUT"
          bottom="OUT"
          pieceFromLeft={1}
          pieceFromTop={1}
        />
        <br />
        <PuzzlePiece
          left="IN"
          top="FLAT"
          right="OUT"
          bottom="IN"
          pieceFromLeft={2}
        />
        <br />
        <PuzzlePiece
          left="IN"
          top="FLAT"
          right="FLAT"
          bottom="OUT"
          pieceFromLeft={3}
        />
        {/* bottom right border */}
        {/*<svg*/}
        {/*  width="365px"*/}
        {/*  height="530px"*/}
        {/*  viewBox="0 0 365 530"*/}
        {/*  xmlns="http://www.w3.org/2000/svg"*/}
        {/*>*/}
        {/*  <rect x="65" y="100" width="300" height="430" fill="gray" />*/}
        {/*  <ellipse cx="45" cy="315" rx="45" ry="65" fill="gray" />*/}
        {/*  <ellipse cx="215" cy="65" rx="45" ry="65" fill="gray" />*/}
        {/*</svg>*/}
      </div>
    </div>
  )
}

export default PageThree
