import React from 'react'
import {
  BrowserRouter as Router,
  NavLink,
  Route,
  Routes,
} from 'react-router-dom'
import Sidebar from './components/Sidebar'
import PageOne from './pages/PageOne'
import PuzzlePage from './pages/PuzzlePage'
import PageThree from './pages/PageThree'
import PageFour from './pages/PageFour'
import HomePage from './pages/HomePage'
import './footer.css'
import { toast, ToastContainer } from 'react-toastify'
function App() {
  return (
    <Router>
      <div
        style={
          {
            // display: 'flex', flexDirection: 'column', minHeight: '100vh'
          }
        }
      >
        <main>
          <link
            href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
            rel="stylesheet"
          ></link>
          <link
            href="https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
            rel="stylesheet"
          ></link>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/page-one" element={<PageOne />} />
            <Route path="/puzzle" element={<PuzzlePage />} />
            <Route path="/page-three" element={<PageThree />} />
            <Route path="/page-four" element={<PageFour />} />
          </Routes>
        </main>
        <footer>
          <div
            className="container"
            style={{
              position: 'relative',
              zIndex: 2,
              margin: '0px auto',
              width: '92vw',
              maxWidth: '1340px',
            }}
          >
            <div className="grid-6-6">
              <div className="order-2-mob footer-address-wrapper">
                <div
                  style={{
                    marginTop: '1rem',
                  }}
                >
                  <p>
                    <b>Acknowledgment of Country</b>
                  </p>
                  <br />
                  <p>
                    Mentem is located on the unceded territory of the Bedegal
                    people. We pay our respects to the traditional owners of the
                    lands where we are based and to Elders past and present.
                  </p>
                  <br />
                  <p>Address: UNSW Sydney, High St, Kensington NSW 2033.</p>
                </div>
                <img
                  alt="UNSW"
                  src="/images/unsw-logo_ATavT.png"
                  decoding="async"
                  loading="lazy"
                  height="55"
                  width="131"
                  className="footer-logo"
                />
              </div>
              <div className="order-1-mob grid-6-6-mob">
                <div className="footer-links">
                  <a href="/">Home</a>
                  <a
                    href="https://www.linkedin.com/company/mentembyunsw/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Careers{' '}
                  </a>
                  <a
                    href="https://www.mentem.co/privacy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy policy
                  </a>
                </div>
                <div>
                  <div className="footer-contact">
                    <p>
                      <a href="mailto:hello@mentem.co">
                        <span style={{ color: 'rgb(186, 97, 255)' }}>
                          hello@mentem.co
                        </span>
                      </a>
                      <br />
                      Sydney, High St, Kensington NSW 2033
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </Router>
  )
}

export default App
