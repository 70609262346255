import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import svgFindInPage from '../images/find-in-page.svg'
import { useSwipeable } from 'react-swipeable'
import { animated, useSpring } from 'react-spring'
import { config as springConfig } from 'react-spring'
import striptags from 'striptags'

import {
  CANVAS_WIDTH,
  CANVAS_HEIGHT,
  BOXES_PER_ROW,
  BOX_WIDTH,
  BOX_HEIGHT,
  BG,
  BG_ALPHA,
  INITIAL_CARDS,
  getRandom,
  playSound,
  detectIOS,
  // detectIOS,
  // detectAndroid,
  // detectMobile,
  // isChrome,
} from '../pages/puzzlePageHelpers'

const CARD_WIDTH = 300
const CARD_HEIGHT = 430

// six board size
// 900 x 860
// puzzle piece knob
const KNOB_RAD_X = 45 // ellipse width radius
const KNOB_RAD_Y = 65 // ellipse height radius

const KNOB_2_EDGE_X = 65 // edge of knob to edge of card
const KNOB_2_EDGE_Y = 100

const BORDER_WIDTH = 10
const MAX_TOTAL_WIDTH = CARD_WIDTH + 2 * KNOB_2_EDGE_X + BORDER_WIDTH
const MAX_TOTAL_HEIGHT = CARD_HEIGHT + 2 * KNOB_2_EDGE_Y + BORDER_WIDTH

const ANIMATION_TRANSITION = 'transform 0.3s ease'

const IS_ENTERING_OFFSET = [1000, -100]

const MOBILE_SENSITIVITY_MULTIPLIER = 10

const PuzzlePiece = (props: any) => {
  let totalWidth = CARD_WIDTH
  let totalHeight = CARD_HEIGHT
  if (props.left === 'OUT') totalWidth += KNOB_2_EDGE_X
  if (props.top === 'OUT') totalHeight += KNOB_2_EDGE_Y
  if (props.right === 'OUT') totalWidth += KNOB_2_EDGE_X
  if (props.bottom === 'OUT') totalHeight += KNOB_2_EDGE_Y

  let offsX = props.left === 'OUT' ? KNOB_2_EDGE_X : 0
  let offsY = props.top === 'OUT' ? KNOB_2_EDGE_Y : 0
  const maskId = `mask-${crypto.randomUUID()}`
  // const [isSwipedOff, setIsSwipedOff] = useState(false)
  const isSwipedOff = props.isSwipedOff
  const setIsSwipedOff = props.setIsSwipedOff

  const [{ x, y }, api] = useSpring(() => ({
    x: 0,
    y: 0,
    immediate: true,
    config: springConfig.stiff,
  }))
  const [zIndex, setZIndex] = useState(0)
  useEffect(() => {
    if (!isSwipedOff) {
      api.set({
        x: 0,
        y: 0,
      })
    }
  }, [isSwipedOff, api])
  useEffect(() => {
    // console.log(`is entering ${props.id} = ${props.isEntering}`)
    if (props.isEntering) {
      api.start({
        x: -IS_ENTERING_OFFSET[0],
        y: -IS_ENTERING_OFFSET[1],
        immediate: true,
        // config: config.stiff,
        // onRest: () => {
        //   props.setWhichCardEntering(null)
        // },
      })
      // give me a timeout of half a second
      setTimeout(() => {
        props.setWhichCardEntering(null)
      }, 500)
    } else {
    }
  }, [
    props.isEntering,
    // props.transformX, props.transformY, api
  ])

  const swipeHandlers = useSwipeable({
    onSwiped: (what) => {
      what.event.preventDefault()
      if (!props.allowEnlarge) {
        // is in ModalOne
        return
      }
      const { dir, absX, deltaX } = what

      if (!props.isEnlarged) {
        setZIndex(0)
        api.start({ x: 0, y: 0, config: springConfig.stiff })
        return
      }
      props.setShowRejectAnimation(false)
      props.setShowAcceptAnimation(false)
      if ((!props.isMobile && absX < 400) || (props.isMobile && absX < 35)) {
        api.start({ x: 0, y: 0, config: springConfig.stiff })
        return
      }
      if (dir === 'Up' || dir === 'Down') {
        api.start({ x: 0, y: 0, config: springConfig.stiff })
        return
      }
      if (dir === 'Left') {
        !props.isMuted && playSound('/whoosh.mp3', null, null, 0.3)
      } else {
        !props.isMuted && playSound('/attach.mp3', 3.3, 3.8, 1.0)
      }
      // Swipe off screen
      api.start({
        x: 5000 * what.vxvy[0],
        y: 5000 * what.vxvy[1],
        onRest: () => {
          props.onSwipeOff(props.id, dir)
          setIsSwipedOff(dir)
        },
        config: springConfig.stiff,
      })
    },
    onSwiping: (what) => {
      what.event.preventDefault()
      if (!props.allowEnlarge) {
        // is in ModalOne
        return
      }
      if (!props.isEnlarged) {
        setZIndex(1000)

        if (props.isMobile) {
          api.set({
            x: what.deltaX * MOBILE_SENSITIVITY_MULTIPLIER,
            y: what.deltaY * MOBILE_SENSITIVITY_MULTIPLIER,
          })
          return
        } else {
          api.set({ x: what.deltaX, y: what.deltaY })
          return
        }
      }
      if (what.dir === 'Left') {
        props.setShowRejectAnimation(true)
        props.setShowAcceptAnimation(false)
      } else if (what.dir === 'Right') {
        props.setShowAcceptAnimation(true)
        props.setShowRejectAnimation(false)
      }
      if (props.isMobile) {
        // console.log(
        //   `props.isMobile x = ${
        //     what.deltaX * MOBILE_SENSITIVITY_MULTIPLIER
        //   }, y = ${what.deltaY * MOBILE_SENSITIVITY_MULTIPLIER}`,
        // )

        // if (what.absX < 40) {
        //   api.start({
        //     x: 0,
        //     y: 0,
        //     config: springConfig.gentle,
        //   })
        //   return
        // } else {
        api.set({
          x: what.deltaX * 2.5, // * MOBILE_SENSITIVITY_MULTIPLIER,
          y: what.deltaY * 2.5, // * MOBILE_SENSITIVITY_MULTIPLIER,
        })
        return
        // }
      } else {
        api.set({ x: what.deltaX, y: what.deltaY })
        return
      }
    },
    onSwipeStart: (what) => {},
    onTap: (what) => {},
    onTouchStartOrOnMouseDown: (what) => {},
    onTouchEndOrOnMouseUp: (what) => {},
    trackMouse: true,
    // trackTouch: true,
    delta: 10,
    // swipeDuration: 250,
    // preventScrollOnSwipe: true,
    touchEventOptions: { passive: false }, // options for touch listeners (*See Details*)
  })
  useEffect(() => {
    setZIndex(1000 * (!props.isEnlarged ? 0 : 1))
  }, [props.isEnlarged])
  // Function to get style based on whether the piece is enlarged or not
  const getPieceStyle = () => {
    if (props.isEnlarged) {
      // props.id == 12 && console.log(`enlarging now ${props.id}`)
      return {
        transform: x.to(
          (x: number) =>
            `translate(${(CANVAS_WIDTH - totalWidth) / 2 + x}px, ${
              (CANVAS_HEIGHT - totalHeight) / 2 + y.get()
            }px)`,
        ),
        // zIndex: 1000, // Bring to front
      }
    } else if (props.isEntering) {
      // props.id == 12 && console.log(`is entering ${props.id}`)
      return {
        transform: x.to(
          (x: number) =>
            `translate(${x + props.transformX + IS_ENTERING_OFFSET[0]}px, ${
              y.get() + props.transformY + IS_ENTERING_OFFSET[1]
            }px)`,
        ),
      }
    } else {
      // if (props.onModal1) debugger
      // props.id == 12 && console.log(`normal ${props.id}`)
      return {
        transform: x.to(
          (x: number) =>
            `translate(${x + props.transformX}px, ${
              y.get() + props.transformY
            }px)`,
        ),
      }
    }
  }
  let bgX = 10
  let bgY = -60
  if (props.pieceIndex === 1) {
    bgX =
      bgX -
      CARD_WIDTH -
      BORDER_WIDTH +
      (props.left === 'OUT' ? KNOB_2_EDGE_X : 0)
  }
  if (props.pieceIndex === 2) {
    bgX =
      bgX -
      CARD_WIDTH -
      BORDER_WIDTH -
      CARD_WIDTH -
      BORDER_WIDTH +
      (props.left === 'OUT' ? KNOB_2_EDGE_X : 0)
  }
  if (props.pieceIndex === 3) {
    bgY =
      bgY -
      CARD_HEIGHT -
      BORDER_WIDTH +
      (props.top === 'OUT' ? KNOB_2_EDGE_Y : 0)
  }
  if (props.pieceIndex === 4) {
    bgY =
      bgY -
      CARD_HEIGHT -
      BORDER_WIDTH +
      (props.top === 'OUT' ? KNOB_2_EDGE_Y : 0)
    bgX =
      bgX -
      CARD_WIDTH -
      BORDER_WIDTH +
      (props.left === 'OUT' ? KNOB_2_EDGE_X : 0)
  }
  if (props.pieceIndex === 5) {
    bgY =
      bgY -
      CARD_HEIGHT -
      BORDER_WIDTH +
      (props.top === 'OUT' ? KNOB_2_EDGE_Y : 0)
    bgX =
      bgX -
      CARD_WIDTH -
      BORDER_WIDTH -
      CARD_WIDTH -
      BORDER_WIDTH +
      (props.left === 'OUT' ? KNOB_2_EDGE_X : 0)
  }

  if (isSwipedOff) {
    return null
  }

  function InnerPiece(props: any) {
    if (!props.isMobile) {
      return (
        <>
          <img
            src={`${props.imgUrl}`}
            alt="puzzle piece icon"
            style={{
              margin: '0 auto',
              userSelect: 'none',
              pointerEvents: 'none',
              touchAction: 'none',
              width: props.imgWidth || 80,
            }}
          />
          <div
            style={{
              width: '270px',
              fontSize: 26,
              lineHeight: '32px',
              fontFamily: 'Inter',
              // fontWeight: '700',
              margin: '0 auto',
              textAlign: 'center',
              height: '180px',
              paddingTop: '45px',
              paddingLeft: props.left === 'IN' ? 60 : 0,
              paddingRight: props.right === 'IN' ? 45 : 0,
              ...(props.descPaddingOverride && {
                padding: `${props.descPaddingOverride[0]}px ${props.descPaddingOverride[1]}px ${props.descPaddingOverride[2]}px ${props.descPaddingOverride[3]}px`,
              }),
            }}
            dangerouslySetInnerHTML={{ __html: props.desc }}
          ></div>
        </>
      )
    } else if (props.onModal1) {
      return (
        <>
          <div
            style={{
              width: 270,
              lineHeight: '48px',
              fontFamily: 'Inter',
              margin: '0 auto',
              // textAlign: 'center',
              // height: '180px',
              paddingTop: 20,
              paddingLeft: 10,
              paddingRight: 0,
              fontSize: detectIOS() ? 14 : 41,
              // fontSize: detectIOS() ? 14 : 41,
            }}
          >
            <b>{striptags(props.desc)}</b>
          </div>
        </>
      )
    } else if (props.isEnlarged) {
      const dPad = props.mobileDescPadding
      return (
        <>
          <img
            src={`${props.imgUrl}`}
            alt="puzzle piece icon"
            style={{
              margin: '0 auto',
              marginTop:
                (props.top === 'OUT' ? -49 : props.top === 'IN' ? -30 : 0) - 10,
              userSelect: 'none',
              pointerEvents: 'none',
              touchAction: 'none',
              width: props.mobileImgWidth
                ? props.mobileImgWidth / 1.8
                : 34 / props.realTrueActualZoom,
            }}
          />
          <div
            style={{
              width: 300,
              lineHeight: '40px',
              fontFamily: 'Inter',
              // fontWeight: '700',
              margin: '0 auto',
              // textAlign: 'center',
              height: '180px',
              // paddingTop: 20 / props.cssZoom,
              paddingTop: 20,
              paddingLeft: props.left === 'IN' ? 60 : 0,
              paddingRight: props.right === 'IN' ? 60 : 0,
              ...(dPad && {
                padding: `${dPad[0]}px ${dPad[1]}px ${dPad[2]}px ${dPad[3]}px`,
              }),
              fontSize: detectIOS() ? 11 : 31,
              // fontSize: detectIOS() ? 11 : 31,
            }}
          >
            <b>{striptags(props.desc)}</b>
          </div>
        </>
      )
    } else {
      const ctr = props.mobileIconCentering
      return (
        <>
          <div
            style={{
              userSelect: 'none',
              pointerEvents: 'none',
              touchAction: 'none',
              margin: `${ctr[0]}px ${ctr[1]}px ${ctr[2]}px ${ctr[3]}px`,
            }}
          >
            <img
              src={`${props.imgUrl}`}
              alt="puzzle piece icon"
              style={{
                margin: '0 auto',
                // width: props.mobileImgWidth || 80 / props.realTrueActualZoom,
                width: props.mobileImgWidth || 180,
              }}
            />
          </div>
        </>
      )
    }
  }

  const swipeMeOff = (dir: string) => {
    props.setShowRejectAnimation(false)
    props.setShowAcceptAnimation(false)
    if (dir === 'Left') {
      !props.isMuted && playSound('/whoosh.mp3', null, null, 0.3)
    } else {
      !props.isMuted && playSound('/attach.mp3', 3.3, 3.8, 1.0)
    }
    // Swipe off screen
    api.start({
      x: dir === 'Left' ? -5000 : 5000,
      y: 0,
      onRest: () => {
        props.onSwipeOff(props.id, dir)
        setIsSwipedOff(dir)
      },
      config: springConfig.stiff,
    })
  }
  return (
    <div
      className="PuzzlePiece"
      id={`PuzzlePiece${props.id}`}
      onClick={(e) => {
        if (props.allowEnlarge && !props.allowEnlarge()) return
        if (!props.isEnlarged) {
          props?.setIsEnlarged?.(true)
          e.stopPropagation()
          return
        }
        // @ts-ignore
        const canvasWidth = CANVAS_WIDTH / window.devicePixelRatio
        const delta = e.clientX - window.innerWidth / 2

        if (delta > canvasWidth / 4) {
          swipeMeOff('Right')
          e.stopPropagation()
        } else if (delta < -canvasWidth / 4) {
          swipeMeOff('Left')
          e.stopPropagation()
        } else {
          props?.setIsEnlarged?.(false)
          e.stopPropagation()
        }
      }}
    >
      {props.isEnlarged && (
        <div
          className="swipeOverlay"
          style={{
            position: 'absolute',
            // width: XXX * MAX_TOTAL_WIDTH,
            // height: XXX * MAX_TOTAL_HEIGHT,
            // left: CANVAS_WIDTH / 2 - (XXX * MAX_TOTAL_WIDTH) / 2,
            // top: CANVAS_HEIGHT / 2 - (XXX * MAX_TOTAL_HEIGHT) / 2,
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            zIndex: 1020,
            display: 'flex',
            flexDirection: 'column',
          }}
          {...swipeHandlers}
        ></div>
      )}

      <animated.div
        style={{
          ...props.style,
          width: props.scale * MAX_TOTAL_WIDTH,
          height: props.scale * MAX_TOTAL_HEIGHT,
          display: 'flex',
          flexDirection: 'column',
          transition: ANIMATION_TRANSITION, // Animate the transform property
          transformOrigin: 'left top', // Ensure the scaling is relative to the top left
          zIndex,
          ...getPieceStyle(),
        }}
      >
        <div
          style={{
            // {...(!props.isMobile && swipeHandlers)}
            touchAction: 'none',
            userSelect: 'none',
            // position: 'relative',
            transform: `translate(${0}px, ${0}px) scale(${
              props.isEnlarged ? 1 : props.scale
            }) rotate(${props.isEnlarged ? 0 : props.rotation})`,
            transition: ANIMATION_TRANSITION, // Animate the transform property
            transformOrigin: 'left top', // Ensure the scaling is relative to the top left
            width: 'fit-content',
            // background: 'green',
            cursor: 'pointer',
          }}
          className="HitBox"
        >
          <svg
            width={totalWidth + BORDER_WIDTH}
            height={totalHeight + BORDER_WIDTH}
            viewBox={`-${BORDER_WIDTH / 2} -${BORDER_WIDTH / 2} ${
              totalWidth + BORDER_WIDTH
            } ${totalHeight + BORDER_WIDTH}`}
            xmlns="http://www.w3.org/2000/svg"
            style={{
              height: '0px',
              width: '0px',
            }}
          >
            <defs>
              <clipPath id={maskId}>
                {props.left === 'OUT' && (
                  <ellipse
                    cx={KNOB_RAD_X}
                    cy={offsY + CARD_HEIGHT / 2}
                    rx={KNOB_RAD_X}
                    ry={KNOB_RAD_Y}
                    fill="black"
                  />
                )}
                {(props.left === 'OUT' || props.left === 'FLAT') && (
                  <rect
                    x={offsX}
                    y={offsY}
                    width={KNOB_2_EDGE_X + 1} // +1 to cover the gap
                    height={CARD_HEIGHT}
                    fill="black"
                  />
                )}
                {props.top === 'OUT' && (
                  <ellipse
                    cx={offsX + CARD_WIDTH / 2}
                    cy={KNOB_RAD_Y}
                    rx={KNOB_RAD_X}
                    ry={KNOB_RAD_Y}
                    fill="black"
                  />
                )}
                {(props.top === 'OUT' || props.top === 'FLAT') && (
                  <rect
                    x={offsX}
                    y={offsY}
                    width={CARD_WIDTH}
                    height={KNOB_2_EDGE_Y + 1} // +1 to cover the gap
                    fill="black"
                  />
                )}
                {props.right === 'OUT' && (
                  <ellipse
                    cx={offsX + CARD_WIDTH + KNOB_2_EDGE_X - KNOB_RAD_X}
                    cy={offsY + CARD_HEIGHT / 2}
                    rx={KNOB_RAD_X}
                    ry={KNOB_RAD_Y}
                    fill="black"
                  />
                )}
                {(props.right === 'OUT' || props.right === 'FLAT') && (
                  <rect
                    x={offsX + CARD_WIDTH - (KNOB_2_EDGE_X + 1)}
                    y={offsY}
                    width={KNOB_2_EDGE_X + 1} // +1 to cover the gap
                    height={CARD_HEIGHT}
                    fill="black"
                  />
                )}
                {props.bottom === 'OUT' && (
                  <ellipse
                    cx={offsX + CARD_WIDTH / 2}
                    cy={offsY + CARD_HEIGHT + KNOB_2_EDGE_Y - KNOB_RAD_Y}
                    rx={KNOB_RAD_X}
                    ry={KNOB_RAD_Y}
                    fill="black"
                  />
                )}
                {(props.bottom === 'OUT' || props.bottom === 'FLAT') && (
                  <rect
                    x={offsX}
                    y={offsY + CARD_HEIGHT - (KNOB_2_EDGE_Y + 1)}
                    width={CARD_WIDTH}
                    height={KNOB_2_EDGE_Y + 1} // +1 to cover the gap
                    fill="black"
                  />
                )}
                <path
                  x={offsX}
                  y={offsY}
                  d="m -7130.6937,0 a 12.100391,17.242767 0 0 1 1.8993,9.2422927 12.100391,17.242767 0 0 1 -12.1005,17.2429243 A 12.100391,17.242767 0 0 1 -7152.9953,9.2422927 12.100391,17.242767 0 0 1 -7151.0401,0 h -29.5427 v 41.445821 a 12.100391,17.242767 0 0 1 5.2971,-1.865147 12.100391,17.242767 0 0 1 12.1004,17.242925 12.100391,17.242767 0 0 1 -12.1004,17.242167 12.100391,17.242767 0 0 1 -5.2971,-1.865147 v 41.570211 h 29.5594 a 12.100391,17.242767 0 0 1 -1.9719,-9.27339 12.100391,17.242767 0 0 1 12.1004,-17.242917 12.100391,17.242767 0 0 1 12.1005,17.242917 12.100391,17.242767 0 0 1 -1.9728,9.27339 h 29.5594 V 72.20138 a 12.100391,17.242767 0 0 1 -5.2963,1.864386 12.100391,17.242767 0 0 1 -12.1005,-17.242167 12.100391,17.242767 0 0 1 12.1005,-17.242925 12.100391,17.242767 0 0 1 5.2963,1.751372 V 0 Z"
                  style={{
                    display: 'inline',
                    opacity: 1,
                    strokeWidth: 0,
                    paintOrder: 'stroke markers fill',
                  }}
                  transform={`scale(3.7795) translate(${
                    offsX ? 7197.775 : 7180.5828
                  }, ${offsY ? 26.495 : 0})`}
                  fill="red"
                />
              </clipPath>
            </defs>
          </svg>

          <div
            style={{
              width: `${totalWidth + BORDER_WIDTH}px`,
              height: `${totalHeight + BORDER_WIDTH}px`,
              backgroundColor: 'white',
              backgroundImage: `url('/images/Mgradient.png')`,
              backgroundPosition: `${bgX + 28}px ${bgY + 90}px`, // Centers the image
              clipPath: `url(#${maskId})`,
              marginLeft: `${BORDER_WIDTH / 2}px`,
              marginTop: `${BORDER_WIDTH / 2}px`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: '853px 661px',
            }}
          ></div>
          <div
            style={{
              marginTop:
                (props.bottom === 'OUT' ? -KNOB_2_EDGE_Y : 0) +
                (props.top === 'IN' ? 40 : 0) + // KNOB_2_EDGE_Y : 0) +
                (props.pieceIndex >= 3 ? 60 : 0) -
                390,
              width: CARD_WIDTH,
              height: CARD_HEIGHT,
              position: 'absolute',
              marginLeft: offsX + BORDER_WIDTH / 2,
            }}
          >
            <InnerPiece {...props} />
          </div>
          {!props.noBorder && (
            <svg
              width={totalWidth + BORDER_WIDTH}
              height={totalHeight + BORDER_WIDTH}
              viewBox={`-${BORDER_WIDTH / 2} -${BORDER_WIDTH / 2} ${
                totalWidth + BORDER_WIDTH
              } ${totalHeight + BORDER_WIDTH}`}
              xmlns="http://www.w3.org/2000/svg"
              style={{
                // height: '0px',
                // width: '0px',
                marginTop: -(totalHeight + BORDER_WIDTH * 1.5),
              }}
              stroke="black" // This is where we're setting the border color
              strokeWidth={BORDER_WIDTH} // And here we're setting the border thickness
            >
              {props.left === 'OUT' && (
                <ellipse
                  cx={KNOB_RAD_X}
                  cy={offsY + CARD_HEIGHT / 2}
                  rx={KNOB_RAD_X}
                  ry={KNOB_RAD_Y}
                  fill="none"
                />
              )}
              {(props.left === 'OUT' || props.left === 'FLAT') && (
                <rect
                  x={offsX}
                  y={offsY}
                  width={KNOB_2_EDGE_X + 1} // +1 to cover the gap
                  height={CARD_HEIGHT}
                  fill="none"
                />
              )}
              {props.top === 'OUT' && (
                <ellipse
                  cx={offsX + CARD_WIDTH / 2}
                  cy={KNOB_RAD_Y}
                  rx={KNOB_RAD_X}
                  ry={KNOB_RAD_Y}
                  fill="none"
                />
              )}
              {props.top !== 'IN' && (
                <rect
                  x={offsX}
                  y={offsY}
                  width={CARD_WIDTH}
                  height={KNOB_2_EDGE_Y + 1} // +1 to cover the gap
                  fill="none"
                />
              )}
              {props.right === 'OUT' && (
                <ellipse
                  cx={offsX + CARD_WIDTH + KNOB_2_EDGE_X - KNOB_RAD_X}
                  cy={offsY + CARD_HEIGHT / 2}
                  rx={KNOB_RAD_X}
                  ry={KNOB_RAD_Y}
                  fill="none"
                />
              )}

              {props.right !== 'IN' && (
                <rect
                  x={offsX + CARD_WIDTH - (KNOB_2_EDGE_X + 1)}
                  y={offsY}
                  width={KNOB_2_EDGE_X + 1} // +1 to cover the gap
                  height={CARD_HEIGHT}
                  fill="none"
                />
              )}

              {props.bottom === 'OUT' && (
                <ellipse
                  cx={offsX + CARD_WIDTH / 2}
                  cy={offsY + CARD_HEIGHT + KNOB_2_EDGE_Y - KNOB_RAD_Y}
                  rx={KNOB_RAD_X}
                  ry={KNOB_RAD_Y}
                  fill="none"
                />
              )}
              {props.bottom !== 'IN' && (
                <rect
                  x={offsX}
                  y={offsY + CARD_HEIGHT - (KNOB_2_EDGE_Y + 1)}
                  width={CARD_WIDTH}
                  height={KNOB_2_EDGE_Y + 1} // +1 to cover the gap
                  fill="none"
                />
              )}

              <path
                d="m -7130.6937,0 a 12.100391,17.242767 0 0 1 1.8993,9.2422927 12.100391,17.242767 0 0 1 -12.1005,17.2429243 A 12.100391,17.242767 0 0 1 -7152.9953,9.2422927 12.100391,17.242767 0 0 1 -7151.0401,0 h -29.5427 v 41.445821 a 12.100391,17.242767 0 0 1 5.2971,-1.865147 12.100391,17.242767 0 0 1 12.1004,17.242925 12.100391,17.242767 0 0 1 -12.1004,17.242167 12.100391,17.242767 0 0 1 -5.2971,-1.865147 v 41.570211 h 29.5594 a 12.100391,17.242767 0 0 1 -1.9719,-9.27339 12.100391,17.242767 0 0 1 12.1004,-17.242917 12.100391,17.242767 0 0 1 12.1005,17.242917 12.100391,17.242767 0 0 1 -1.9728,9.27339 h 29.5594 V 72.20138 a 12.100391,17.242767 0 0 1 -5.2963,1.864386 12.100391,17.242767 0 0 1 -12.1005,-17.242167 12.100391,17.242767 0 0 1 12.1005,-17.242925 12.100391,17.242767 0 0 1 5.2963,1.751372 V 0 Z"
                style={{
                  display: 'inline',
                  opacity: 1,
                  strokeWidth: 0,
                  paintOrder: 'stroke markers fill',
                }}
                transform={`scale(3.95) translate(${
                  (offsX ? 7197.775 : 7180.5828) - 2
                }, ${(offsY ? 26.495 : 0) - 3})`}
                // transform={`scale(4.00) translate(${
                //   offsX ? 7197.275 : 7170.5828
                // }, ${offsY ? 25.495 : -2})`}
                fill="black"
              />
            </svg>
          )}
        </div>
      </animated.div>
    </div>
  )
}

export default PuzzlePiece
